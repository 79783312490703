<template>
  <div v-if="data" class="v-saved__question-item">
    <TheButton
      v-if="isActive"
      :class="[
        'v-saved__question-tab',
        wasCorrect
          ? 'v-saved__question-tab--success'
          : 'v-saved__question-tab--fail',
      ]"
      @click="onShowModalQuestion(data)"
    >
      <strong></strong>
      <span>{{ data.text }}</span>
      <span class="v-saved__question-icon"
        ><i
          :class="['vb-icon', wasCorrect ? 'vb-icon-check' : 'vb-icon-close']"
        ></i>
        {{ wasCorrect ? $t('RESULT_SUCCESS') : $t('RESULT_FAIL') }}</span
      >
    </TheButton>
    <TheButton
      v-else
      :to="`/lesson/${data.doc.slugPath}#audio-section-${data.doc.sectionId}`"
      class="v-saved__question-tab v-saved__lesson-tab"
      target="_blank"
    >
      <strong></strong>
      <span>{{ data.doc.title }}</span>
    </TheButton>
    <tippy
      theme="trafikk"
      :allowHTML="true"
      :interactive="true"
      trigger="click"
      :ref="(tippy) => (tippyItems[savedId] = tippy)"
    >
      <TheButton class="v-saved__dots">
        <span class="v-saved__dot"></span><span class="v-saved__dot"></span
        ><span class="v-saved__dot"></span>
      </TheButton>
      <template #content>
        <TheButton type="inline" @click="removeItem(), hideTippy(savedId)">{{
          $t('SAVED_REMOVE')
        }}</TheButton>
      </template>
    </tippy>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import useModal from '@/composables/useModal'
import { Question } from 'types/interface/ComposeQuestion'
import TheButton from '@/components/TheButton.vue'
import useSaved from '@/composables/useSaved'

export default defineComponent({
  name: 'SavedElement',

  components: {
    TheButton,
  },

  props: {
    data: Object,
    i: Number,
    isActive: Boolean,
  },
  emits: ['delete-item'],

  setup(props, { emit }) {
    const { setModal } = useModal()
    const { preselectedCorrect } = useSaved()

    const onShowModalQuestion = (q: Question) => {
      setModal('ModalQuestion', {
        content: q,
        type: 'concept',
      })
    }
    const removeItem = () => {
      emit('delete-item')
    }

    const tippyItems = ref([])
    const wasCorrect = computed(() =>
      preselectedCorrect.value.includes(props.data.id),
    )
    const hideTippy = (tippyId: number) => {
      tippyItems.value[tippyId].hide()
    }

    return {
      onShowModalQuestion,
      removeItem,
      tippyItems,
      hideTippy,
      wasCorrect,
    }
  },
})
</script>

<style lang="scss">
.v-saved {
  &__question-tab {
    line-height: 3rem;
    width: calc(100% - rem(48px));
    text-decoration: none;
    border-radius: rem(12px);
    color: $saved-question-text;
    box-shadow: 0 0 rem(16px) rem(9px) $saved-question-shadow;
    padding: 0 rem(16px);
    justify-content: left;
    gap: rem(8px);
    font-weight: normal;
    strong {
      &:before {
        content: counter(saved) '.';
      }
    }
    &--success {
      box-shadow: 0 0 rem(10px) rem(-2px) lighten($saved-question-success, 15%);
    }
    &--fail {
      box-shadow: 0 0 rem(10px) rem(-2px) lighten($saved-question-fail, 15%);
    }

    span {
      width: calc(100% - 1rem);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }

    .v-saved__question-icon {
      min-width: rem(50px);
      width: rem(50px);
      font-weight: bold;
      @include bp($xsmall) {
        margin-left: rem(10px);
      }
      .vb-icon {
        font-weight: bold;
        &-check {
          color: $results-item-success;
        }
        &-close {
          color: $results-item-fail;
        }
      }
    }
  }
  &__lesson-tab {
    width: 100%;
    min-width: rem(140px);
  }
  &__dots {
    display: flex;
    gap: 2px;
    flex-direction: column;
    background: transparent;
    padding: rem(16px) rem(22px);
  }
  &__dot {
    width: 0px;
    height: 0px;
    border: 2px solid $saved-dot-bg;
  }
}
</style>
