
import { computed, defineComponent, ref } from 'vue'
import useModal from '@/composables/useModal'
import { Question } from 'types/interface/ComposeQuestion'
import TheButton from '@/components/TheButton.vue'
import useSaved from '@/composables/useSaved'

export default defineComponent({
  name: 'SavedElement',

  components: {
    TheButton,
  },

  props: {
    data: Object,
    i: Number,
    isActive: Boolean,
  },
  emits: ['delete-item'],

  setup(props, { emit }) {
    const { setModal } = useModal()
    const { preselectedCorrect } = useSaved()

    const onShowModalQuestion = (q: Question) => {
      setModal('ModalQuestion', {
        content: q,
        type: 'concept',
      })
    }
    const removeItem = () => {
      emit('delete-item')
    }

    const tippyItems = ref([])
    const wasCorrect = computed(() =>
      preselectedCorrect.value.includes(props.data.id),
    )
    const hideTippy = (tippyId: number) => {
      tippyItems.value[tippyId].hide()
    }

    return {
      onShowModalQuestion,
      removeItem,
      tippyItems,
      hideTippy,
      wasCorrect,
    }
  },
})
