
import { defineComponent, ref, onBeforeMount, onMounted, computed } from 'vue'
import { useHead } from '@vueuse/head'
import { useTenant } from '@/core'
import { useI18n } from 'vue-i18n'
import Topbar from '@/components/Topbar.vue'
import TheButton from '@/components/TheButton.vue'
import useQuestions from '@/composables/useQuestions'
import useSaved from '@/composables/useSaved'
import { useRoute, useRouter } from 'vue-router'

import {
  getSavedBookmarks,
  bookmarksToDisplay,
  removeBookmark,
} from '@/services/bookmarkContent'
import SavedElement from '@/components/SavedElement.vue'
import useCourseHandler from '@/composables/useCourseHandler'

export default defineComponent({
  components: {
    Topbar,
    TheButton,
    SavedElement,
  },

  setup() {
    const { tenant } = useTenant()
    const { t } = useI18n()
    const { fetchAllQuestions, allQuestions, questionsByIds } = useQuestions()
    const {
      refreshPreselectedQuestions,
      sanitizePreselectedQuestions,
      deleteQuestion,
      preselectedQuestions,
      preselectedCorrect,
    } = useSaved()
    const { currentCourse } = useCourseHandler()
    const route = useRoute()
    const router = useRouter()
    const selectedContent = ref('questions')

    const checkContentLength = computed(
      () => preselectedQuestions.value.length || lessonsSet.value.length,
    )

    const getSectionItems = computed(() =>
      isActive('questions') ? questionsSet.value : lessonsSet.value,
    )

    const questionsSet = computed(() => {
      const questionsToDisplay = questionsByIds(
        preselectedQuestions.value,
      ).filter((q) => !!q)
      if (
        preselectedQuestions.value.length &&
        allQuestions.value.length &&
        preselectedQuestions.value.length !== questionsToDisplay.length
      ) {
        sanitizePreselectedQuestions(questionsToDisplay)
      }
      return questionsToDisplay
    })

    const lessonsSet = computed(() => {
      return bookmarksToDisplay.value
    })

    const deleteSectionItem = (item: { id: string }, idx: number) => {
      isActive('questions')
        ? deleteQuestion(item.id)
        : deleteLesson(item.id, idx)
    }

    const setContent = (content: any) => {
      selectedContent.value = content

      selectedContent.value === 'questions'
        ? router.push({ path: route.path, query: { tab: 'questions' } })
        : router.push({ path: route.path, query: { tab: 'lessons' } })
    }

    const isActive = (content: string): boolean => {
      return selectedContent.value === content
    }

    useHead({
      title: computed(() => `${t('SAVED_HEADER')} - ${tenant.value.name}`),
    })

    onBeforeMount(async () => {
      await refreshPreselectedQuestions()
      setContent(route.query.tab)
    })

    onMounted(async () => {
      if (!allQuestions.value.length) {
        fetchAllQuestions(currentCourse.value)
      }
      await getSavedBookmarks()
    })

    const deleteLesson = (bookmarkId: string, index: number) => {
      lessonsSet.value.splice(index, 1)
      removeBookmark(bookmarkId)
    }

    return {
      preselectedQuestions,
      questionsSet,
      allQuestions,
      deleteQuestion,
      isActive,
      lessonsSet,
      setContent,
      deleteLesson,
      checkContentLength,
      getSectionItems,
      deleteSectionItem,
      preselectedCorrect,
    }
  },
})
