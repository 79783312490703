<template>
  <Topbar back-url="/" back-icon="back" />
  <div class="v-saved">
    <h1 class="v-saved__heading">
      {{ $t('SAVED_HEADER') }}
    </h1>
    <div v-if="checkContentLength" class="v-saved__description">
      {{
        $t('SAVED_DESCRIPTION', [
          preselectedQuestions.length,
          lessonsSet.length,
        ])
      }}
    </div>
    <div class="v-saved__buttons">
      <TheButton
        :class="[
          'v-saved__button-question',
          { 'v-saved__button--active': isActive('questions') },
        ]"
        @click="setContent('questions')"
      >
        {{ $t('SAVED_QUESTIONS') }}
      </TheButton>
      <TheButton
        :class="[
          'v-saved__button-bookmark',
          { 'v-saved__button--active': isActive('lessons') },
        ]"
        @click="setContent('lessons')"
      >
        {{ $t('SAVED_LESSONS') }}</TheButton
      >
    </div>
    <div class="v-saved__question-list">
      <SavedElement
        :is-active="isActive('questions')"
        :data="item"
        :i="index"
        v-for="(item, index) of getSectionItems"
        :key="index"
        @delete-item="deleteSectionItem(item, index)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onBeforeMount, onMounted, computed } from 'vue'
import { useHead } from '@vueuse/head'
import { useTenant } from '@/core'
import { useI18n } from 'vue-i18n'
import Topbar from '@/components/Topbar.vue'
import TheButton from '@/components/TheButton.vue'
import useQuestions from '@/composables/useQuestions'
import useSaved from '@/composables/useSaved'
import { useRoute, useRouter } from 'vue-router'

import {
  getSavedBookmarks,
  bookmarksToDisplay,
  removeBookmark,
} from '@/services/bookmarkContent'
import SavedElement from '@/components/SavedElement.vue'
import useCourseHandler from '@/composables/useCourseHandler'

export default defineComponent({
  components: {
    Topbar,
    TheButton,
    SavedElement,
  },

  setup() {
    const { tenant } = useTenant()
    const { t } = useI18n()
    const { fetchAllQuestions, allQuestions, questionsByIds } = useQuestions()
    const {
      refreshPreselectedQuestions,
      sanitizePreselectedQuestions,
      deleteQuestion,
      preselectedQuestions,
      preselectedCorrect,
    } = useSaved()
    const { currentCourse } = useCourseHandler()
    const route = useRoute()
    const router = useRouter()
    const selectedContent = ref('questions')

    const checkContentLength = computed(
      () => preselectedQuestions.value.length || lessonsSet.value.length,
    )

    const getSectionItems = computed(() =>
      isActive('questions') ? questionsSet.value : lessonsSet.value,
    )

    const questionsSet = computed(() => {
      const questionsToDisplay = questionsByIds(
        preselectedQuestions.value,
      ).filter((q) => !!q)
      if (
        preselectedQuestions.value.length &&
        allQuestions.value.length &&
        preselectedQuestions.value.length !== questionsToDisplay.length
      ) {
        sanitizePreselectedQuestions(questionsToDisplay)
      }
      return questionsToDisplay
    })

    const lessonsSet = computed(() => {
      return bookmarksToDisplay.value
    })

    const deleteSectionItem = (item: { id: string }, idx: number) => {
      isActive('questions')
        ? deleteQuestion(item.id)
        : deleteLesson(item.id, idx)
    }

    const setContent = (content: any) => {
      selectedContent.value = content

      selectedContent.value === 'questions'
        ? router.push({ path: route.path, query: { tab: 'questions' } })
        : router.push({ path: route.path, query: { tab: 'lessons' } })
    }

    const isActive = (content: string): boolean => {
      return selectedContent.value === content
    }

    useHead({
      title: computed(() => `${t('SAVED_HEADER')} - ${tenant.value.name}`),
    })

    onBeforeMount(async () => {
      await refreshPreselectedQuestions()
      setContent(route.query.tab)
    })

    onMounted(async () => {
      if (!allQuestions.value.length) {
        fetchAllQuestions(currentCourse.value)
      }
      await getSavedBookmarks()
    })

    const deleteLesson = (bookmarkId: string, index: number) => {
      lessonsSet.value.splice(index, 1)
      removeBookmark(bookmarkId)
    }

    return {
      preselectedQuestions,
      questionsSet,
      allQuestions,
      deleteQuestion,
      isActive,
      lessonsSet,
      setContent,
      deleteLesson,
      checkContentLength,
      getSectionItems,
      deleteSectionItem,
      preselectedCorrect,
    }
  },
})
</script>

<style lang="scss">
.v-saved {
  padding: rem($topbar-height) 1rem 1rem;
  min-height: calc(100vh - $topbar-height);
  box-shadow: 0 -0.25rem 0.5rem $app-container-outline;

  &__heading {
    padding: 0 2rem;
    font-size: 2rem;
    font-weight: 700;
    margin: 2rem 0 0.5rem;
  }
  &__description {
    padding: 0 rem(32px) rem(32px);
  }
  &__question-list {
    display: flex;
    flex-direction: column;
    gap: rem(16px);
    padding: 1rem 0 1rem 2rem;
    counter-reset: saved;
  }
  &__question-item {
    display: flex;
    align-content: center;
    position: relative;
    counter-increment: saved;
  }
}
.v-saved__buttons {
  display: flex;
  justify-content: center;
  background-clip: padding-box;
}
.v-saved__button-question,
.v-saved__button-bookmark {
  position: relative;
  display: inline-block;
  padding: 0.5rem 1rem;
  border: 1px solid $saved-button;
  border-radius: 0.5rem;
  width: rem(155.5px);
}

.v-saved__button-question {
  right: rem(-2px);
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right-color: transparent;
}

.v-saved__button-bookmark {
  border-left-color: transparent;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  left: rem(-2px);
}

.v-saved__button-question.v-saved__button--active {
  border-bottom-right-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.v-saved__button-bookmark.v-saved__button--active {
  border-bottom-left-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}

.v-saved__button--active {
  background-color: $saved-button;
  z-index: 1;
  color: $saved-button__text;
}
</style>
